import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {PrepareTrainingLink} from "./PrepareTrainingLink.jsx";

function TrainingPanel({groupID, cardsList, customListCards, visibleCustom}) {

    const tail = 
    groupID ? 
        (visibleCustom ? 'custom' : groupID) 
        : (visibleCustom ? 'custom' : 'dictionary');

    
    return (
        <div className="trainings-panel">
            <div className="trainings">Тренировки: 
                <PrepareTrainingLink to={"learning"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Повторение</PrepareTrainingLink>
                <PrepareTrainingLink to={"word-translation"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Слово-Перевод</PrepareTrainingLink>
                <PrepareTrainingLink to={"translation-word"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Перевод-Слово</PrepareTrainingLink>
                <PrepareTrainingLink to={"image-word"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Картинка-Слово</PrepareTrainingLink>
                <PrepareTrainingLink to={"writing"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Письмо</PrepareTrainingLink>
                <PrepareTrainingLink to={"listening"} cardsList={cardsList} groupID={groupID} customListCards={customListCards} visibleCustom={visibleCustom}>Аудирование</PrepareTrainingLink>
            </div>
            
            <div className="tests">Тесты:
                <Link to={`/tests/sprint/${tail}`}><span>Спринт</span></Link>
                <Link to={`/tests/comparison/${tail}`}><span>Сопоставление</span></Link>
                <Link to={`/tests/selection/${tail}`}><span>Подбор</span></Link>
            </div>
        </div>
    )
}

export default TrainingPanel;