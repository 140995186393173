import React from "react";

export function ProfileSidebar ({page}) {
    const pathname = page.pathname;

    function SidebarTemplate({children}) {
        return (
            <div className="sidebar">
                <div className="sidebar--wrapper">{children}</div>
            </div>
        )
    }

    function MainSidebar() {
        return (
            <SidebarTemplate>
                <div>Текст 1</div>
                <div>Текст 2</div>
                <div>Текст 3</div>
            </SidebarTemplate>
        )
    }


    const getSidebar = (pathname) => {
        switch (pathname){
            case "/profile":
                return MainSidebar;
            default:
                return null;
        }
    }

    const Sidebar = getSidebar(pathname);

    return Sidebar ? (< Sidebar />) : null;

}