import React, { useEffect, useState } from "react";
import AudioRecorder from "./service-components/AudioRecorder.jsx";
import AudioFileUploader from "./service-components/AudioFileUploader.jsx";

export default function FormSoundButtons({onFileUpload}) {
    const [activeButton, setActiveButton] = useState(null);
    const [stateUpload, setStateUpload] = useState(null);

    return (
        <div className={"sound--btns-block" + (activeButton ? " " + activeButton : "") + (stateUpload ? " " + stateUpload : "") }>
            <AudioFileUploader onFileUpload={onFileUpload} activeButton={activeButton} setActiveButton={setActiveButton} stateUpload={stateUpload} setStateUpload={setStateUpload} />
            <AudioRecorder onFileUpload={onFileUpload} activeButton={activeButton} setActiveButton={setActiveButton} stateUpload={stateUpload} setStateUpload={setStateUpload} />
        </div>
    )
}