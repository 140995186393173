import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useMessage} from '../../components/service-components/MessageContext.jsx';


export function ProfileMain () {
    const user = useSelector(state => state.user);
    console.log("user", user);

    const {addMessage} = useMessage();

    const [editMode, setEditMode] = useState(false);

    const [formData, setFormData] = useState({});

    const activeEditMode = () => {
        setEditMode(true);
    }

    const updateUserData = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    }

    const saveUserData = () => {
        setEditMode(false);
        addMessage({type: "success", text: "Данные сохранены"});
    }

    useEffect(() => {
        if(user.load) {
            for (let key in user.userData) {
                setFormData(prevState => ({...prevState, [key]: user.userData[key]}));
            }
        }
    }, [user]);

    return (
    <div className="profile-main profile-block wrapper">
        <div className="head">
        <h1>Профиль</h1>
        {!editMode && <div className="edit btn blue" onClick={activeEditMode}>Редактировать</div>}
        {editMode && <div className="edit btn blue" onClick={saveUserData}>Сохранить</div>}
        </div>
        <div className="info">
            <div className="left-block">
                <div className="image--wrapper"></div>
                <div className="btn blue t-nowrap">Изменить пароль</div>
            </div>
            <div className="right-block">
                <div className="user-data">
                    <div className="item name">
                        <label>Имя</label>
                        <div>{editMode ? <input type="text" name="name" value={formData["name"]} placeholder={user.userData["name"]} onChange={updateUserData}/> : <span>{formData["name"]}</span>}</div>
                    </div>
                    <div className="item email">
                        <label>Email</label>
                        <div><span>{user.userData.email}</span></div>
                    </div>
                    <div className="item tg">
                        <label>Ник в Telegram</label>
                        <div>{editMode ? <input type="text" name="tg_name" value={formData["tg_name"]} onChange={updateUserData} placeholder={user.userData["tg_name"]}/> : <span>{formData["tg_name"]}</span>}</div>
                    </div>
                        <div className="item level">
                        <label>Уровень языка</label>
                        <div>{editMode
                         ? <select name="level_hebrew" onChange={updateUserData}>
                            <option value='alef' selected={formData["level_hebrew"]=='alef'}>alef</option>
                            <option value='bet' selected={formData["level_hebrew"]=='bet'}>bet</option>
                         </select>
                         : <span>{formData["level_hebrew"]}</span>}</div>
                    </div>
                    <div className="achievements">Достижения <span></span></div>
                </div>
            </div>
        </div>
    </div>
    );

}