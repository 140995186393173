import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ModalPopup, {toggleModal} from "./ModalPopup.jsx";
import AddCardForm from "./AddCardForm.jsx";
import CardsList from "./CardsList.jsx";
import DeletedCardsList from "./DeletedCardsList.jsx";
import EditGroupForm from "./EditGroupForm.jsx";
import { useSelector, useDispatch } from 'react-redux';
import { setDictionary } from '../redux/actions/dictionary.js';
import $api from '../http/api.js';
import {useMessage} from '../components/service-components/MessageContext.jsx';
import CheckUserRole from "../components/service-components/CheckUserRole.jsx";

const GroupCards = () => {
    const user = useSelector(state => state.user);
    const stateGroupCards = useSelector(state => state.groupCards);

    const dispatch = useDispatch();
    const stateDictionary = useSelector((state) => state.dictionary);

    const [event, setEvent] = useState(null);

    const {addMessage} = useMessage();

    const [cardsInfo, setCardsInfo] = React.useState(null);
    const [groupInfo, setGroupInfo] = React.useState(null);
    const[openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);
    const [btnDictionary, setBtnDictionary] = React.useState({});
    const stateBtnDictionary = {
        inDictionary: {
            btn: "inDictionary",
            text: "В словаре",
            style: "green-btn"
        },
        addToDictionary: {
            btn: "addToDictionary",
            text: "Добавить в словарь",
            style: "blue-btn",
            func: () => addGroupToDictionary(id)
        },
        delFromDictionary: {
            btn: "delFromDictionary",
            text: "Удалить",
            style: "red-btn",
            func: () => delGroupFromDictionary(id)
        }
    }

    const {id} = useParams();

    const updateGroupCardsInfo = () => {
        $api.get(`/getCardsGroup/${id}`).then((result) => {
            setCardsInfo(result.data.cardsList);
            setGroupInfo(result.data.groupInfo);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        updateGroupCardsInfo();
    }, [id]);

    useEffect(() => {
        if (event == null) return;
        updateGroupCardsInfo();
    }, [event]);

/*     useEffect(()=> {
        if (!stateDictionary.isLoaded) {
            $api.get("/getUserDictionary").then((result) => {
                dispatch(setDictionary(result.data));
            });
        }
    },[]); */

    useEffect(()=> {
        console.log("btnDictionary",btnDictionary);
    }, [btnDictionary]);

    React.useEffect(() => {
        console.log("Изменился стейт groupCards", stateGroupCards);
        setGroupInfo(stateGroupCards.groups.find(group => group.group_id == id));
        $api.get(`/getCardsGroup/${id}`, {
            headers: {
              'Cache-Control': 'no-cache'
            }}).then((result) => {
            setCardsInfo(result.data.cardsList);
            console.log("Обновил список карточек", result.data.cardsList);
        });
    },[stateGroupCards]);

    useEffect(()=> {
        console.log("Dictionary. Изменился стейт", stateDictionary);
        if (stateDictionary && stateDictionary.groups.findIndex(x => x.group_id == id) !== -1) {
            setBtnDictionary(stateBtnDictionary.inDictionary);
        }
        else if(stateDictionary) {setBtnDictionary(stateBtnDictionary.addToDictionary)}
    }, [stateDictionary])

    function delGroupFromDictionary() {
        $api.post(`/delGroupFromDictionary/${id}`)
        .then(result => {
            if (result.status == 200) {
                addMessage({type: "success", text: "Набор удален из словаря"});
                dispatch(setDictionary(result.data));
            }
        })
        .catch(error => {
            const text = error.response?.data?.message ? error.response.data.message : error.message;
            addMessage({type: "error", text: text});
        });
    }


    function addGroupToDictionary(groupId){
        $api.post(`/addGroupToDictionary/${groupId}`)
        .then(result => {
            if (result.status == 200) {
                console.log("Dictionary. Получен словарь:", result.data);
                dispatch(setDictionary(result.data));
                setBtnDictionary(stateBtnDictionary.inDictionary);
                addMessage({type: "success", text: "Добавлено в словарь"});
            }
        })
        .catch(error => {
            const text = error.response?.data?.message ? error.response.data.message : error.message;
            addMessage({type: "error", text: text})
        });
    }


    return (
        <>
            {groupInfo && cardsInfo ? (
                <div className="cards-group">
                                    {(user.isAuth && ["teacher", "admin"].includes(user.userData.role)) && (
                                        <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title}>{modalComponent?.component}</ModalPopup>
                                    )}  
                    <div className="cards-group--wrapper">
                        <div className="cards-group--content">
                            <div className="group-info">
                                <div>
                                    <div className="group-level"><span>{groupInfo.level}</span></div>
                                    <div className="group-name"><span>{groupInfo.group_name}</span></div>
                                    {((user.isAuth && ["teacher", "admin"].includes(user.userData.role))) && (
                                        <div className="group-btn blue-btn" onClick={() => {setModalComponent({component: <AddCardForm groupId={id} />, title: 'Добавить карточку'});toggleModal(openModal, setOpenModal)}}>Добавить карточку</div>
                                    )}

                                    {((user.isAuth && ["teacher", "admin"].includes(user.userData.role))) && (
                                        <div className="group-btn blue-btn" onClick={() => {setModalComponent({component: <EditGroupForm groupInfo={groupInfo} />, title: 'Редактировать набор'});toggleModal(openModal, setOpenModal)}}>Редактировать</div>
                                    )}

                                    {user.isAuth && (
                                        <div className={`group-btn ${btnDictionary.style}`} 
                                        onMouseEnter={() => {
                                            if(btnDictionary.btn === "inDictionary"){
                                                setBtnDictionary(stateBtnDictionary.delFromDictionary)
                                            }}
                                        }
                                        onMouseLeave={() => {
                                            if(btnDictionary.btn === "delFromDictionary"){
                                                setBtnDictionary(stateBtnDictionary.inDictionary)
                                            }}
                                        }
                                        onClick={btnDictionary.func}
                                        >{btnDictionary.text}</div>
                                    )
                                    }
                                    
                                </div>
                                <div className="group-description"><span>{groupInfo.group_description}</span></div>
                            </div>
                            {stateDictionary?.deleted_cards?.length > 0 && 
                            cardsInfo.filter(card => stateDictionary?.deleted_cards?.includes(card.card_id)).length > 0 && 
                            <DeletedCardsList cardsList={cardsInfo.filter(card => stateDictionary.deleted_cards.includes(card.card_id))}
                            groupID={id} setEvent={setEvent} btnDictionary={btnDictionary}/> }
                            <CardsList cardsList={stateDictionary?.deleted_cards?.length ? cardsInfo.filter(card => !stateDictionary?.deleted_cards?.includes(card.card_id)) : cardsInfo} groupID={id} setEvent={setEvent} offset={cardsInfo.filter(card => stateDictionary?.deleted_cards?.includes(card.card_id)).length}/>
                        </div> 
                    </div>
                </div>
            ) : (
                <div className="cards-group">
                <div className="cards-group--wrapper">
                    <div className="cards group--content">
                        <div>Загрузка...</div>
                    </div> 
                </div>
            </div>
            )}
        </>
    );
}
export default GroupCards;
//export default CheckUserRole(GroupCards, { isPage: false }, ["teacher", "admin"]);