import React from "react";
import {ProfileMain, ProfileProgress, ProfileSettings, ProfileFeedback} from '../profile';

export function ProfileLayout ({page}) {
    const pathname = page.pathname;
    const getComponent = (pathname) => {
        switch (pathname){
            case "/profile/progress":
                return ProfileProgress;
            case "/profile/settings":
                return ProfileSettings;
            case "/profile/feedback":
                return ProfileFeedback;
            default:
                return ProfileMain;
        }
    }

    const Component = getComponent(pathname);

    return (< Component />);

}