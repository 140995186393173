import React, {useEffect, useState, useMemo, useRef} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import $api from '../../http/api.js';
import { useLocation, useParams } from 'react-router-dom';
import {userLogin} from '../../redux/actions/user.js';
import { useSelector, useDispatch } from 'react-redux';
import {updateUserSettings} from "../../redux/actions/user.js";
import FrameTrainingBlockCards from './FrameTrainingBlockCards.jsx';
import MultiTrainingBlock from './MultiTrainingBlock.jsx';

function FrameTraining() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isLoadSettings = useRef(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    let type = searchParams.get('type');
    const key = searchParams.get('key');

    const rules = {
        "learning": {
            url: "/embed/learning/",
            requiredData: ["img", "sound", "trascription"],
            typeCard: []
        },
        "word-translation": {
            url: "/embed/word-translation/",
            requiredData: [],
            typeCard: []
        },
        "translation-word": {
            url: "/embed/translation-word/",
            requiredData: [],
            typeCard: []
        },
        "image-word": {
            url: "/embed/image-word/",
            requiredData: ["img"],
            typeCard: []
        },
        "writing": {
            url: "/embed/writing/",
            requiredData: [],
            typeCard: []
        }
    }

    async function authUserFromKI(){
        const userData = await $api.post("/user/authUserFromKI", {key}).then((response) => response.data);
        localStorage.setItem('userData', JSON.stringify(userData.user));
        localStorage.setItem('refreshToken', userData.refreshToken);
        dispatch(userLogin(userData.user));
        await $api.get("/user/getUserSettings").then((result) => {
            dispatch(updateUserSettings(result.data.settings));
        });
        isLoadSettings.current = true;
    }

    useEffect(() => {
        authUserFromKI();
    }, []);

    return (
        <>
        {user.load && isLoadSettings.current &&
            <div className="block-cards embed training" style={{backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center"}} tabIndex={0}>
            <div className="card--wrapper embed">
                {type == "multi" && <MultiTrainingBlock id={id} ukey={key}/>}
                {type != "multi" && <FrameTrainingBlockCards />}
            </div>
        </div>
        }
        </>
    )
}

export default FrameTraining;