import React from "react";
import BlockTrainingWordTranslate from './BlockTrainingWordTranslate.jsx';
import BlockTrainingImageWord from './BlockTrainingImageWord.jsx';
import BlockTrainingWriting from './BlockTrainingWriting.jsx';
import BlockTrainingListening from './BlockTrainingListening.jsx';

export default function TrainingLayout({...props}) {
    const {type} = props;
    const getTypeTrainingComponent = (type) => {
      switch (type) {
        case 'word-translation':
        case 'translation-word':
          return BlockTrainingWordTranslate;
        case 'image-word':
          return BlockTrainingImageWord;
        case 'writing':
          return BlockTrainingWriting;
        case 'listening':
          return BlockTrainingListening;
        default:
          return null;
      }
    };
  
    const TypeTrainingComponent = getTypeTrainingComponent(type);
  
    if (!TypeTrainingComponent) {
      return <div>Ошибка выбранной тренировки</div>;
    }
  
    return (
        <TypeTrainingComponent  {...props} />
        )
  }