import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {useMessage} from '../../components/service-components/MessageContext.jsx';
import {userLogin} from '../../redux/actions/user.js';
import $api from "../../http/api.js";

const CheckUserRole = function (WrappedComponent, {isPage = false}, allowedRoles = []) {
    return function CheckUserRoleWrapper(props) {
        const {addMessage} = useMessage();
        const user = useSelector(state => state.user);
        const navigate = useNavigate();
        const userLocStorage = localStorage.getItem('userData');
        const userToken = localStorage.getItem('refreshToken');
        console.log('CheckUserRole user: ', user);
    
        const userData = (userLocStorage && userLocStorage !== 'undefined') ? JSON.parse(userLocStorage) : null;
        useEffect(() => {
            if (!userToken) {
                localStorage.removeItem('userData');
                return isPage ? navigate("/login") : null;
            }
            if (!user.load) return;
            if (user.load) {
                if (!user.isAuth) {
                    addMessage({type: "error", text: "Закрыто для гостей"});
                    return isPage ? navigate("/login") : null;
                }
                if (allowedRoles.length > 0 && !allowedRoles.includes(user.userData.role)) {
                    addMessage({type: "error", text: "Доступ закрыт"});
                    return isPage ? navigate("/login") : null;
                }
            }
            else {
                //localStorage.removeItem('userData');
                return navigate("/login");
            }
        }, [WrappedComponent, user.load, navigate, isPage, allowedRoles]); 

        return <WrappedComponent {...props} />;
    };
};

export default CheckUserRole;
