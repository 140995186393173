import React, {useEffect, useState, useMemo, useRef} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import $api from '../../http/api.js';

function MultiTrainingBlock({ukey, id}) {
    const [groupInfo,setGroupInfo] = useState([]);

    React.useEffect(() => {
        $api.get(`/getCardsGroup/${id}`).then((result) => {
            setGroupInfo(result.data.groupInfo);
        });
    }, [id]);

    return (
        <div className="menu-training-wrapper">
            <div className="title">Набор: {groupInfo.group_name}</div>
            <div className="subtitle">Выберите тип тренировки:</div>
            <div className="menu-training--content">
                <Link to={`/embed/training?id=${id}&key=${ukey}&type=word-translation`}>
                    <div className="menu-training-item">
                        <div>Перевод</div>
                    </div>
                </Link>
                <Link to={`/embed/training?id=${id}&key=${ukey}&type=image-word`}>
                    <div className="menu-training-item">
                        <div>Картинка</div>
                    </div>
                </Link>
                <Link to={`/embed/training?id=${id}&key=${ukey}&type=writing`}>
                    <div className="menu-training-item">
                        <div>Письмо</div>
                    </div>
                </Link>
                <Link to={`/embed/training?id=${id}&key=${ukey}&type=listening`}>
                    <div className="menu-training-item">
                        <div>Аудирование</div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default MultiTrainingBlock;