import logo from './logo.svg';
//import authUser from "./services/authUser.js";
import React from 'react';
import { useNavigate } from "react-router-dom";
import {Header, GroupCards} from './components';
import {Training} from './components/training';
import {Home, Library, Dictionary, SignIn, AdminPanel, Profile} from './pages';
import { Switch, Route, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';
import $api from './http/api.js';
import { useSelector, useDispatch } from 'react-redux';
import {userLogin, userLoad, updateUserSettings} from './redux/actions/user.js';
import { setDictionary } from './redux/actions/dictionary.js';
import FrameTraining from './components/frame_training/FrameTraining.jsx';
import Tooltip from './components/service-components/Tooltip.jsx';

function App() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    
    React.useEffect(() => {
        if (!location.pathname.startsWith("/embed/training")) {
        const userLocStorage = localStorage.getItem('userData');
        const userData = (userLocStorage && userLocStorage !== 'undefined') ? JSON.parse(userLocStorage) : null;
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
                $api.post('/user/auth')
                    .then(result => {
                        if (result.status === 200 && result.data.status === 'allowed') {
                            dispatch(userLogin(result.data.user));

                            if (!state.dictionary.isLoaded) {
                                $api.get("/getUserDictionary").then((result) => {
                                    dispatch(setDictionary(result.data));
                                })
                                .catch(e => {
                                        console.log("Пользователь не авторизован для словаря");
                                   })
                            }
                            $api.get("/user/getUserSettings").then((result) => {
                                console.log("getUserSettings result.data.settings", result.data.settings);
                                dispatch(updateUserSettings(result.data.settings));
                            })
                        }
                        else if (result.status === 200 && result.data.status === 'no token') {
                            dispatch(userLoad({}));
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('userData');
                        }
                        else {
                            //localStorage.clear();
                            console.log("Ошибка авторизации в приложении", result.data);
                            navigate("/login");
                        }
                    })
                .catch(e => {
                    console.log("Ошибка авторизации: ", e);
                    //localStorage.clear();
                    navigate("/login");
                });
        }
        else {
            //localStorage.clear();
            dispatch(userLoad());
            navigate("/login");
        }
    }}, []);

    if (location.pathname.startsWith("/embed/training")) {
        import('./styles.css');
        return <FrameTraining/>;
    }
    if (location.pathname.startsWith("/admin-p")) {
        return <AdminPanel />
    }
    if (location.pathname.startsWith("/profile")) {
        import('./styles.css');
        return <Profile />
    }

    else {import('./styles.css');}
  return (
    <>
<Tooltip />
<Header/>

<content>
    <left-column>
        <div className="sidebar sidebar--wrapper">
            <div className="sidebar--content">
                <div className="items">
                    <div className="items-group">
                        <div className="items-title"><i className='bx bx-brain'></i><a><span>Тренировки</span></a></div>
                        <div className="items-list">
                            <div className="item">Повторение</div>
                            <div className="item">Слово-перевод</div>
                            <div className="item">Перевод-слово</div>
                            <div className="item">Аудирование</div>
                            <div className="item">Викторина</div>
                        </div>
                    </div>
                    <div className="items-group">
                        <div className="items-title"><i className='bx bx-task'></i><a><span>Тесты</span></a></div>
                        <div className="items-list">
                            <div className="item">Спринт</div>
                            <div className="item">Сопоставление</div>
                            <div className="item">Подбор</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </left-column>

    <right-column>
        <div className="content--wrapper">
            <div className="content">
            <Routes>
                <Route path='/training/word-translation/:id' element={<Training type="word-translation" custom={false}/>} />
                <Route path='/training/translation-word/:id' element={<Training type="translation-word" custom={false}/>} />
                <Route path='/training/image-word/:id' element={<Training type="image-word" custom={false}/>} />
                <Route path='/training/writing/:id' element={<Training type="writing" custom={false}/>} />
                <Route path='/training/listening/:id' element={<Training type="listening" custom={false}/>} />
                
                <Route path='/training/word-translation/custom' element={<Training type="word-translation" custom={true}/> } />
                <Route path='/training/translation-word/custom' element={<Training type="translation-word" custom={true}/>} />
                <Route path='/training/image-word/custom' element={<Training type="image-word" custom={true}/>} />
                <Route path='/training/writing/custom' element={<Training type="writing" custom={true}/>} />
                <Route path='/training/listening/custom' element={<Training type="listening" custom={true}/>} />

                <Route path='/' element={<Home />} />
                <Route path='/login' element={<SignIn />} />
                <Route path="/group/:id" element={<GroupCards groups={[]}/>} />
                <Route path="/library" element={<Library />} />
                <Route path="/dictionary" element={<Dictionary />} />
            </Routes>
                    
            </div>
        </div>
    </right-column>
</content>
<footer></footer>
</>
  );
}

export default App;
