import React, { useState, useEffect, useRef } from 'react';
import { useMessage } from './MessageContext.jsx';

export default function AudioRecorder({ onFileUpload, activeButton, setActiveButton, stateUpload, setStateUpload}) {
  const [isPrepareRecording, setIsPrepareRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [dataArray, setDataArray] = useState([]);
  const [audio, setAudio] = useState(null);
  const [durationAudio, setDurationAudio] = useState(0);
  let startRecordTime = useRef(null);
  const [chunks, setChunks] = useState([]);
  const chunksRef = useRef([]);
  const [analyser, setAnalyser] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [smoothDataArray, setSmoothDataArray] = useState([]);
  const [audioCtx, setAudioCtx] = useState(null);
  const canvasRef = useRef(null);
  const recordRef = useRef(null);
  const blockRecord = useRef(null);
  const timelineCanvas = useRef(null);
  const animationIdRef = useRef(null);
  const { addMessage } = useMessage();
  const [opacity, setOpacity] = useState(0);

  const initRecorder = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      setAudioCtx(audioCtx);

      const streamContext = audioCtx.createMediaStreamSource(stream);

      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 256;
      setAnalyser(analyser);

      const bufferLength = analyser.frequencyBinCount;
      setDataArray(new Uint8Array(bufferLength));
      setSmoothDataArray(new Float32Array(bufferLength));

      streamContext.connect(analyser);

      const biquadFilter = audioCtx.createBiquadFilter();
      biquadFilter.type = 'highpass';
      biquadFilter.frequency.setValueAtTime(300, audioCtx.currentTime);
      streamContext.connect(biquadFilter);
      biquadFilter.connect(analyser);

      mediaRecorder.onstart = () => {
        startRecordTime = Date.now();
      }

      mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          setChunks((prevChunks) => {
            const updatedChunks = [...prevChunks, e.data];
            chunksRef.current = updatedChunks;
            return updatedChunks;
          });
        } else {
          console.log('No data available');
        }
      };
      const newAudio = new Audio();
      setAudio(newAudio);
      newAudio.onended = () =>{
        setIsPlaying(false);
      }



      mediaRecorder.onstop = () => {
        console.log('Chunks before stop:', chunksRef.current);
        const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
        setAudioBlob(blob);
        processAudioData(blob);
        const audioUrl = window.URL.createObjectURL(blob);
        setAudioUrl(audioUrl);
        setChunks([]);
        const newAudioFile = new File([blob], 'recorded-audio.webm', { type: 'audio/webm' });
        setAudioFile(newAudioFile);
        onFileUpload(newAudioFile);


          let duration = (Date.now() - startRecordTime) / 1000;
          console.log("duration", duration);
          if (!isNaN(duration) && duration !== Infinity) {
            let minutes = Math.floor(duration / 60);
            let seconds = Math.floor(duration % 60);
        
            let formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            setDurationAudio(formattedDuration); // Ваш метод для отображения продолжительности
          } else {
            console.error("Ошибка при определении продолжительности аудиофайла");
          }
      };

      return mediaRecorder;
    } catch (error) {
      console.log('Ошибка доступа к микрофону: ', error);
      return null;
    }
  };

  const processAudioData = (audioData) => { // blob или file
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const reader = new FileReader();
    reader.onload = () => {
      audioContext.decodeAudioData(reader.result, (buffer) => {
        setAudioBuffer(buffer);
        let duration = buffer.duration;
            console.log('Audio duration:', buffer.duration, 'seconds');
      });
    };
    reader.readAsArrayBuffer(audioData);
  };

  const drawWaveform = () => {
    if (!audioBuffer || !timelineCanvas.current) return;
    
    const canvas = timelineCanvas.current;
    const context = canvas.getContext('2d');
    const { width, height } = canvas;
    context.clearRect(0, 0, width, height);

    const data = audioBuffer.getChannelData(0);
    const step = Math.ceil(data.length / width);
    const amp = height / 2;

    for (let i = 0; i < width; i++) {
      let min = 1.0;
      let max = -1.0;
      for (let j = 0; j < step; j++) {
        const datum = data[(i * step) + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      context.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
    }
  };

  useEffect(() => {
    drawWaveform();
  }, [audioBuffer]);

  useEffect(() => {
    if (!stateUpload) return;
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 150);

    return () => clearTimeout(timer);
  }, [stateUpload]);

  useEffect(() => {
    if (isRecording) {
      draw();
    }

    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
    };
  }, [isRecording]);

  const startRecord = async () => {
    const mediaRecorder = await initRecorder();
    if (mediaRecorder) {
      setStateUpload("uploading");
      setIsRecording(true);
      mediaRecorder.start(1000);
      setMediaRecorder(mediaRecorder);
      console.log('Запись началась');
    } else {
      // addMessage({type: "error", text: "Нужно дать разрешение для записи"});
      // setTimeout(() => initRecorder(), 3000);
    }
  };

  const stopRecord = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      console.log('Запись закончилась');
      recordRef.current.classList.remove('recording');
      setStateUpload("uploaded");
      const context = canvasRef.current.getContext('2d');
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const playRecord = () => {
    if (audio) {
      audio.src = audioUrl;
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play();
        setIsPlaying(true);
      }
    }
  };

  const deleteRecord = () => {
    if (audio) {
      blockRecord.current.style.opacity = 0;
      blockRecord.current.style.width = 0;
      setTimeout(()=> {
        audio.src = "";
        setIsPlaying(false);
        setStateUpload("prepare");
        onFileUpload("");
        setAudioFile(null);
        setOpacity(0);
      }, 200)
    }
  }

  const clickButtonRecord = () => {
    if (!isPrepareRecording) {
      setActiveButton("record");
      setIsPrepareRecording(true);
      setStateUpload("prepare");
    } else {
      recordRef.current.classList.toggle('recording');
      if (isRecording) {
        stopRecord();
      } else {
        startRecord();
      }
    }
  };

  const clickButtonCancel = () => {
    if (!isPrepareRecording) return;
    setIsPrepareRecording(false);
    setStateUpload(null);
    setActiveButton(null);
  }

  const draw = () => {
    if (!isRecording) return;

    animationIdRef.current = requestAnimationFrame(draw);

    analyser.getByteFrequencyData(dataArray);

    const smoothingFactor = 0.7;
    for (let i = 0; i < 70; i++) {
      smoothDataArray[i] = (smoothingFactor * smoothDataArray[i]) + ((1 - smoothingFactor) * dataArray[i]);
    }

    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext('2d');
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const innerRadius = 20;
    const maxBarHeight = 10;
    const barWidth = (2 * Math.PI) / 20;

    const minFrequencyIndex = Math.floor(300 / (audioCtx.sampleRate / 2) * analyser.frequencyBinCount);
    const maxFrequencyIndex = Math.ceil(4000 / (audioCtx.sampleRate / 2) * analyser.frequencyBinCount);

    let maxAmplitude = 0;
    for (let i = minFrequencyIndex; i <= maxFrequencyIndex; i++) {
      if (smoothDataArray[i] > maxAmplitude) {
        maxAmplitude = smoothDataArray[i];
      }
    }

    for (let i = minFrequencyIndex; i <= maxFrequencyIndex; i++) {
      let barHeight = smoothDataArray[i] / 2;
      if (barHeight < 50) {
        barHeight = 0;
      }

      barHeight = maxBarHeight * (barHeight / maxAmplitude);
      barHeight = Math.min(barHeight, maxBarHeight);

      const angle = (i - minFrequencyIndex) * barWidth;

      const x1 = centerX + Math.cos(angle) * innerRadius;
      const y1 = centerY + Math.sin(angle) * innerRadius;
      const x2 = centerX + Math.cos(angle) * (innerRadius + barHeight);
      const y2 = centerY + Math.sin(angle) * (innerRadius + barHeight);

      canvasCtx.strokeStyle = `rgb(50,50,${barHeight + 100})`;
      canvasCtx.lineWidth = 2;
      canvasCtx.beginPath();
      canvasCtx.moveTo(x1, y1);
      canvasCtx.lineTo(x2, y2);
      canvasCtx.stroke();
    }
  };

  return (
    <>
      <div className="audioRecorder" style={{ width: "50px", height: "50px", justifyContent: "center", alignItems: "center" }}>
        <canvas ref={canvasRef} width="50" height="50" style={{ position: "absolute", width: "50px", height: "50px" }}></canvas>
        <i ref={recordRef} className='bx bxs-microphone btn' style={{ position: "absolute" }} data-tooltip="Записать с микрофона" onClick={clickButtonRecord}></i>
      </div>
      {stateUpload === "prepare" && activeButton === "record" && (
        <>
      <div style={{ marginLeft: "35px", transition: 'opacity 0.5s', opacity }}>Нажмите на микрофон для записи</div>
      <div className="btn blue" style={{ transition: 'opacity 0.5s', opacity }} onClick={clickButtonCancel}>Отмена</div>
      </>
      )
      }
      {stateUpload === "uploading" && activeButton === "record" && (
        <div style={{ marginLeft: "45px", transition: 'opacity 0.5s', display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: '25px', opacity }}>
          <span>Идет запись...</span>
          <div className="btn blue" onClick={stopRecord}>Остановить</div>
        </div>
      )}
      {stateUpload === "uploaded" && activeButton === "record" && 
      <div ref={blockRecord} className="block-record" style={{ opacity }}>
        <i class={`bx bx-${isPlaying ? "pause" : "play"} btn`} data-tooltip="Воспроизвести" onClick={playRecord}></i>
        {durationAudio}
        <canvas ref={timelineCanvas} width="120" height="40"></canvas>
        <i class='bx bx-x red' data-tooltip="Удалить" style={{fontSize: "24px"}} onClick={deleteRecord}></i>
      </div>}
    </>
  );
}
