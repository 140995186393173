import React, { useState, useEffect, useRef } from 'react';


export default function AudioFileUploader({onFileUpload, activeButton, setActiveButton, stateUpload, setStateUpload}) {

    const audioRef = useRef(null);
    const [audio, setAudio] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioBuffer, setAudioBuffer] = useState(null);
    const blockRecord = useRef(null);
    const timelineCanvas = useRef(null);
    const [durationAudio, setDurationAudio] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [opacity, setOpacity] = useState(0);

    const handleAudioChange = (e) => {
        console.log("handleAudioChange");
        const selectedAudio = e.target.files[0];
        if (!selectedAudio) return;
        console.log("selectedAudio name", selectedAudio.name);
        setAudioUrl(URL.createObjectURL(selectedAudio));
        processAudioData(selectedAudio);
        setStateUpload("uploaded");
        onFileUpload(selectedAudio);
      };

      const handleAudioButtonClick = () => {
        audioRef.current?.click();
        setActiveButton("file");
        setStateUpload("prepare");
      };

      const processAudioData = (audioData) => { // blob или file
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const reader = new FileReader();
        reader.onload = () => {
          audioContext.decodeAudioData(reader.result, (buffer) => {
            setAudioBuffer(buffer);
            let duration = buffer.duration;
            console.log('Audio duration:', buffer.duration, 'seconds');
            if (!isNaN(duration) && duration !== Infinity) {
                let minutes = Math.floor(duration / 60);
                let seconds = Math.floor(duration % 60);
            
                let formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                setDurationAudio(formattedDuration);
              }
          });
        };
        reader.readAsArrayBuffer(audioData);
      };
    
      const drawWaveform = () => {
        if (!audioBuffer || !timelineCanvas.current) return;
        
        const canvas = timelineCanvas.current;
        const context = canvas.getContext('2d');
        const { width, height } = canvas;
        context.clearRect(0, 0, width, height);
    
        const data = audioBuffer.getChannelData(0);
        const step = Math.ceil(data.length / width);
        const amp = height / 2;
    
        for (let i = 0; i < width; i++) {
          let min = 1.0;
          let max = -1.0;
          for (let j = 0; j < step; j++) {
            const datum = data[(i * step) + j];
            if (datum < min) min = datum;
            if (datum > max) max = datum;
          }
          context.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
        }
      };

      useEffect(() => {
        drawWaveform();
      }, [audioBuffer]);

      useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);

        newAudio.onended = () => {
            setIsPlaying(false);
        };

        return () => {
            newAudio.pause();
            newAudio.src = "";
        };
    }, []);
    
      useEffect(() => {
        if (!stateUpload) return;
        const timer = setTimeout(() => {
          setOpacity(1);
        }, 150);
    
        return () => clearTimeout(timer); // Очистка таймера при размонтировании компонента
      }, [stateUpload]);

      const playRecord = () => {
        if (audio) {
          audio.src = audioUrl;
          if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
          } else {
            audio.play();
            setIsPlaying(true);
          }
        }
      };
    
      const deleteRecord = () => {
        if (audio) {
          blockRecord.current.style.opacity = 0;
          blockRecord.current.style.width = 0;
          setTimeout(()=> {
            audio.src = "";
            setIsPlaying(false);
            setStateUpload(null);
            setActiveButton(null);
            onFileUpload("");
            setOpacity(0);
          }, 200)
        }
      }


    return (
        <>
            <i className='bx bxs-file-import btn fileUploader' data-tooltip="Загрузить файл" onClick={handleAudioButtonClick}></i>
            <input type="file" name="audio" style={{ display: "none" }} ref={audioRef} onChange={handleAudioChange} accept="audio/*,audio/mp3,audio/waw"/>
            {stateUpload === "uploaded" && activeButton === "file" && 
                <div ref={blockRecord} className="block-record" style={{ opacity }}>
                    <i class={`bx bx-${isPlaying ? "pause" : "play"} btn`} data-tooltip="Воспроизвести" onClick={playRecord}></i>
                    {durationAudio}
                    <canvas ref={timelineCanvas} width="120" height="40"></canvas>
                    <i class='bx bx-x red' data-tooltip="Удалить" style={{fontSize: "24px"}} onClick={deleteRecord}></i>
                </div>
            }
        </>
    )
}