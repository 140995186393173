import React, { useState, useEffect } from "react";
import $api from "../../http/api";

function PlatformSettings({integration, fetchKIGroups}) {
  const [data, setData] = useState({ groups: [], weeks: [] });
  const [formData, setFormData] = useState({});
  const [currentThemeData, setCurrentThemeData] = useState({});
  const [activeGroupKI, setActiveGroupKI] = useState(null);
  const [activeKursKI, setActiveKursKI] = useState(null);
  const [activeWeekKI, setActiveWeekKI] = useState(null);
  const [formMessage, setformMessage] = useState('');

  const groupCardsID = integration.groupCardsID;
  const groupKIID = integration.groupID ?? null;
  const typeGroupKI = integration.type;
  const kursID = integration.kursID;

  // загружаем с сервера данные при монтировании или изменении пропсов
  useEffect(() => {
    const dataDB = { // общий объект для данных о группах, темы добавляются отдельно
      "groups": [],
      "weeks": [] // недели по которым распределяются темы
    };
    Promise.all([
      $api.get('/getKIGroups').then(result => {
        const type = typeGroupKI === "group" ? "groups" : "groups_lite"; // получаем список групп обучения КИ
        result.data[type].forEach(row => {
          dataDB.groups.push({  //пушим список групп
            "group_id": row.id, // id группы обучения
            "group_name": row.name, // название группы
            "kurs_id": row.kurs_id // курс который связан с группой
          });
        });
        dataDB.groups.push({  // пушим КК
          "group_id": "kk", // 
          "group_name": "Клубная карта", 
          "kurs_id": null // курса тут нет
        });
      }),
      $api.get(`/getKIFrames/${groupCardsID}`).then(result => { // получаем данные интеграций из БД. groupCardsID- это id набора карточек
        result.data.frames.forEach(row => {
          console.log("setFormData1",row.type_training,integration.typeTraining);
          if (row.group_type == integration.type && row.type_training == integration.typeTraining) {
            console.log("setFormData",row.group_id, JSON.parse(row.lessons));
            setFormData(prevData => ({
              ...prevData,
              [row.group_id]: JSON.parse(row.lessons)
            }));
          }
        });
      }),
    ]).then(() => {
      setActiveGroupKI(integration.groupID.toString()); // обновление: ставим активную тему из данных пропса
      setData(dataDB); // записываем данные о группах в стейт data
    });
  }, [integration]);

  useEffect(() => { 
    console.log("data", data);
    if (data.weeks.length > 0) {
      setActiveWeekKI(data.weeks[0].week_id);
    }
  }, [data.groups]);

  useEffect(() => { // срабатывает при изменении группы обучения или при монтировании из пропса
    if (!activeGroupKI) {
      return;
    }
    // const kurs_id = data?.groups?.find(group => group.group_id === activeGroupKI).kurs_id; // ищем курс связанный с группой
    // if (kurs_id) {
    //   getKIThemes(kurs_id); //получаем недели и темы курса
    // }
    getKIThemes(kursID);
  }, [activeGroupKI]);

  const getKIThemes = (kursID) => { // отдельный запрос на получение данных о неделях
    $api.post('/getKIThemes', {kurs_id: kursID}).then(result => {
      let weeksData = [];
      result.data.themes.forEach(row => {
        let index = weeksData.findIndex(item => item.week_id === row.week_id); //если в массиве недели нет, то создаемм неделю с данными о темах в неделе
        if (index === -1) {
          weeksData.push({
            "week_id": row.week_id,
            "week_name": row.week_name,
            "themes": [{"theme_id": row.theme_id, "theme_name": row.theme_name}]
          });
        } else { // неделя есть, тогда очередную тему добавляем в неделю
          weeksData[index].themes.push({
            "theme_id": row.theme_id,
            "theme_name": row.theme_name
          });
        }
      });
      setData(prevData => ({ // пушим данные о неделях с темами в стейт
        ...prevData,
        weeks: weeksData
      }))
    })
  }

    // Сохраняем значения полей ввода
    const updateData = (e) => {
        const checkedList = e.target.closest('form').querySelectorAll("input[type='checkbox']:checked");
        const themesArr = [...checkedList].map(input => input.value);
        setFormData((prevData) => ({
          ...prevData,
          [activeGroupKI]: themesArr
        }))
        console.log("FormData", formData);
      }; 

      // Логируем formData
      React.useEffect(() => {
        console.log("FormData", formData);
      }, [formData]);

      // Отправляем данные формы
      const handleSubmit = (e) => {
        e.preventDefault();
        const dataObjects = [];
        const formdata = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          const groupKIName = data.groups.find(group => group.group_id === key).group_name;
          dataObjects.push({
            groupKI: key,
            kursID: kursID,
            groupKIName: groupKIName,
            lessons: value,
            typeGroupKI: integration.type,
            typeTraining: integration.typeTraining
          });
        }
        console.log("dataObjects", dataObjects);
        formdata.append("data", JSON.stringify(dataObjects));
          $api.post(`/platformSettings/${groupCardsID}`, formdata)
          .then((response) => {
            console.log('Успешно отправлено:', response.data);
            setformMessage("Изменения сохранены");
            fetchKIGroups();
          })
          .catch((error) => {
            console.error('Ошибка при отправке данных:', error);
          });
      };

      console.log("currentThemeData[activeGroupKI]", currentThemeData, activeGroupKI);
      console.log("formData", formData);

    return (
        <>
        <div className="form--wrapper platform-settings">
            <form onSubmit={handleSubmit}>
                <div className="panel" style={{display: "flex", gap: "15px"}}>
                <div className="form--item">
                    <select name="groupKI" onChange={(e) => setActiveGroupKI(e.target.value)} required >
                      {data.groups.map(group => (
                        <option key={group.group_id} value={group.group_id} selected={activeGroupKI == group.group_id}>{group.group_name}</option>
                      ))}
                    </select>
                </div>
                <div className="form--item">
                    <select name="weekKurs" onChange={(e) => setActiveWeekKI(e.target.value)} required >
                      {data.weeks.map(week => (
                        <option key={week.week_id} value={week.week_id}>{week.week_name}</option>
                      ))}
                    </select>
                </div>
                </div>
                <label>Темы курса</label>
                {data.weeks.map(week => (
                  <div className={"form--item themes" + (week.week_id == activeWeekKI ? "" : " hide")} style={{display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "15px"}}>
                  {week.themes.map(theme => (
                    <p key={theme.theme_id}><input checked={
                      (currentThemeData?.[activeGroupKI] && currentThemeData[activeGroupKI].includes(theme.theme_id)) ||
                      (formData?.[activeGroupKI]?.includes(theme.theme_id)) ? true : false
                    }  onChange={updateData} type="checkbox" name="theme" value={theme.theme_id} style={{width: "auto"}}/> {theme.theme_name}</p>
                  ))}
                </div>
                ))}
                <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                  <button type="submit">Сохранить изменения</button>
                  <div className="form--message">{formMessage}</div>
                </div>
            </form>
        </div>
        </>
    );

}


export default PlatformSettings;