const userDataInitialState = {
    userData: {},
    isAuth:false,
    load: false
}

const userReducer = (state = userDataInitialState, action) => {
    switch(action.type) {
        case "USER_LOGIN":
            return {
                ...state,
                isAuth: true,
                load: true,
                userData: action.payload
            }
        case "USER_LOGOUT":
            return {
                ...state,
                isAuth: false,
                userData: {}
            }
        case "USER_LOAD":
            return {
                ...state,
                load: true,
            }
        case "UPDATE_USER_SETTINGS":
            console.log("updateUserSettings", action);
            return {
                ...state,
                userData: {
                    ...state.userData,
                    settings: action.payload,
                }
            }
        default: return state;
    }
};

export default userReducer;