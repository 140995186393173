import React, {useState, useRef} from "react";

function BlockTrainingImageWord({cardsData, type, answers, isUserChoiceMade, settings, setActiveAnswer}) {

    const wordType = "translate";
    const wordAnswer = "word";
    const [cardClasses, setCardClasses] = React.useState({
        firstCardClass: "currentCard",
        secondCardClass: "nextCard"
    });

    const [width, setWidth] = useState(window.innerWidth);
    const firstCardClass = cardClasses.firstCardClass;
    const secondCardClass = cardClasses.secondCardClass;
    const [isAnimate, setAnimate] = React.useState(false);
    const [cards, setCard] = React.useState({
        currentCard: cardsData[0],
        nextCard: cardsData[1]
    });

    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const imageRef2 = useRef(null);
    const [options, setOptions] = React.useState(() => genOptions(cardsData[0][wordAnswer], answers));
    const [activeOption, setActiveOption] = React.useState(null);
    const [isChecked, setIsChecked] = React.useState(false);
    const [hide, setHide] = React.useState('');

    const prevDataRef = useRef();

    const currentCard = cards.currentCard;
    const nextCard = cards.nextCard;

    React.useEffect(() => {
        setActiveOption(null);
        setIsChecked(false);
    }, [cardsData]);

    React.useEffect(() => {
        if (prevDataRef.current !== undefined && prevDataRef.current !== cardsData) {
            console.log('cardsData changed from', prevDataRef.current, 'to', cardsData);
          }
          prevDataRef.current = cardsData;
    }, [cardsData]);

    React.useEffect(()=> {
        setOptions(genOptions(cardsData[0][wordAnswer], answers));
    }, [currentCard]);

    React.useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement) return;
            const wrapper = wrapperRef?.current;
            const wrapperBounds = wrapper.getBoundingClientRect();
            const wrapperCenterX = wrapperBounds.width/2 + wrapperBounds.left;
            const wrapperCenterY = wrapperBounds.height/2 + wrapperBounds.top;
            if (currentCard && imageRef) {
                let imageCenterX, imageCenterY, imageX, imageY;
                imageCenterX = currentCard.img_data?.img_dcx + wrapperCenterX;
                imageCenterY = currentCard.img_data?.img_dcy + wrapperCenterY;
                imageX = (imageCenterX - currentCard.img_data?.img_w/2) - wrapperBounds.left;
                imageY = (imageCenterY - currentCard.img_data?.img_h/2) - wrapperBounds.top;
                imageRef.current.style.top = imageY + "px";
                imageRef.current.style.left = imageX + "px";
                imageRef.current.style.height = currentCard.img_data?.img_h + "px";
                imageRef.current.style.width = currentCard.img_data?.img_w + "px";
            }

            if(nextCard && imageRef2) {
                let imageCenterX, imageCenterY, imageX, imageY;
                console.log("nextCard", nextCard);
                imageCenterX = nextCard.img_data?.img_dcx + wrapperCenterX;
                console.log("imageCenterX", imageCenterX);
                console.log("img_dcx: " + nextCard.img_data?.img_dcx + " + wrapperCenterX: " + wrapperCenterX + " = " + (nextCard.img_data?.img_dcx + wrapperCenterX));
                imageCenterX = nextCard.img_data?.img_dcx + wrapperCenterX;
                console.log("imageCenterX", imageCenterX);
                imageCenterY = nextCard.img_data?.img_dcy + wrapperCenterY;
                imageX = (imageCenterX - nextCard.img_data?.img_w/2) - wrapperBounds.left;
                imageY = (imageCenterY - nextCard.img_data?.img_h/2) - wrapperBounds.top;
                imageRef2.current.style.top = imageY + "px";
                console.log("imageX", (imageCenterX - nextCard.img_data?.img_w/2) - wrapperBounds.left);
                imageRef2.current.style.left = imageX + "px";
                imageRef2.current.style.height = nextCard.img_data?.img_h + "px";
                imageRef2.current.style.width = nextCard.img_data?.img_w + "px";
                console.log({imageX:imageX, imageY:imageY, wrapperCenterX:wrapperCenterX, wrapperCenterY:wrapperCenterY, imageCenterX: imageCenterX, imageCenterX: imageCenterY, wrapperBounds: wrapperBounds, img_data: nextCard.img_data});
            }

            // console.log({imageX:imageX, imageY:imageY, wrapperCenterX:wrapperCenterX, wrapperCenterY:wrapperCenterY, imageCenterX: imageCenterX, imageCenterX: imageCenterY, wrapperBounds: wrapperBounds, img_data: nextCard.img_data});
      }, [currentCard, nextCard, width]);

      React.useEffect(() => {
        const handleResize = (event) => {
          setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    React.useEffect(() => {
        setOptions(() => genOptions(cardsData[0][wordAnswer], answers));
    }, [answers, type])


    React.useEffect(() => {
        if (isUserChoiceMade) {
            setOptions([]);
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
                 setCard(prevState => ({
                    ...prevState,
                    currentCard: prevState.nextCard,
                    nextCard: cardsData[1]
                })); 
                setCardClasses({
                    firstCardClass: secondCardClass,
                    secondCardClass: firstCardClass
                });

            },200)
        }
    },[cardsData]);
    

    function clickOnOption(option) {
        if (!isChecked) {
        const isCorrect = option === currentCard[wordAnswer];
        setActiveOption({option, isCorrect});
        setIsChecked(true);
        setActiveAnswer({
            card: currentCard,
            isCorrectAnswer: isCorrect,
            typeTraining: type
        });
    }
    }

    function genOptions(currentWord, answers) {
        console.log("answers into genOptions", answers);
        let filteredAnswers = answers.filter(answer => answer !== currentWord);
        let options = filteredAnswers
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);
        options = [...options, currentWord]
            .sort(() => 0.5 - Math.random());
    
        return options;
    }


    const classNameFirstCard = firstCardClass + hide + (isAnimate && firstCardClass == 'currentCard' ? ' animate' : '');
    const classNameSecondCard = secondCardClass + hide + (isAnimate && secondCardClass == 'currentCard' ? ' animate' : '');


    return (

        <div className="card" style={settings.order ? {flexDirection: "row-reverse"} : {}}>
                        <div className="left-side">
                    {firstCardClass === 'currentCard' || typeof(nextCard) !== "undefined" ? (
                    <div className={"card--content " + classNameFirstCard}>
                        <div className="card-img--wrapper" ref={firstCardClass == 'currentCard' ? wrapperRef : null}>
                            <div className="card-img--content">
                            <img ref={firstCardClass == 'currentCard' ? imageRef : imageRef2} alt="preview" src={cards[firstCardClass]?.["img"]} style={{
          height: "auto", 
          width: "100%", 
          objectFit: "cover",
          position: "relative",
          top: "0px", 
          left: "0px",
        }}
        />
      </div>
                        </div>
                    </div>
                    ) : null}
                    {secondCardClass === 'currentCard' || typeof(nextCard) !== "undefined" ? (
                        <div className={"card--content " + classNameSecondCard}>
                        <div className="card-img--wrapper" ref={secondCardClass == 'currentCard' ? wrapperRef : null}>
                            <div className="card-img--content">
                            <img alt="preview" ref={secondCardClass == 'currentCard' ? imageRef : imageRef2} src={cards[secondCardClass]?.["img"]} style={{
          height: "auto", 
          width: "100%", 
          objectFit: "cover",
          position: "relative",
          top: "0px", 
          left: "0px",
        }}
        />
                            </div>
                        </div>
                    </div>
                    ): null}
                        </div>
                        <div className="right-side">
                        <div className="options">
                <div className="options-title"><span>Выберите правильный вариант</span></div>
                <div className="options-list">
                    {options.map((option)=> (
                    <div key={option} onClick ={()=> clickOnOption(option)} className={"options-item" + (activeOption && option === currentCard[wordAnswer] ? " correct" :  (activeOption && activeOption.option === option ? " wrong" : ""))}><span>{option}</span></div>)
                    )}
                </div>
            </div>
                        </div>
        </div>
            );
};

export default BlockTrainingImageWord;