import React from "react";
import {Link} from 'react-router-dom';
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth-service";
import {userLogin} from '../redux/actions/user.js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function SignIn () {
    const dispatch = useDispatch();
    const[email, setEmail] = React.useState('');
    const[password, setPassword] = React.useState('');
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const {addMessage} = useMessage();

    async function sendLogin() {
        try {
            const userData = await AuthService.login(email, password).then((response) => response.data);
            await localStorage.setItem('userData', JSON.stringify(userData.user));
            await localStorage.setItem('refreshToken', userData.refreshToken);
            await dispatch(userLogin({...userData.user, ref:"signIn"}));
            navigate("/");
            console.log('Текущее состояние хранилища:', state);

        }
        catch(e) {
            if (e.response && e.response.data && e.response.data.message) {
                console.log(e.response.data);
            }
        }
    }

    async function sendReg() {
        //addMessage({type: "error", text: "Регистрация временно закрыта"});
        //return;
        try {
            const userData = await AuthService.register(email, password).then((response) => response.data);
            console.log('Полученные данные:', userData);
            localStorage.setItem('token', userData.accessToken);
            localStorage.setItem('userData', JSON.stringify(userData.user));
            dispatch(userLogin(userData.user));
            navigate("/");
            console.log('Текущее состояние хранилища:', state);

        }
        catch(e) {
            if (e.response && e.response.data && e.response.data.message) {
                console.log(e.response.data);
            }
        }
    }

    return (
        <div>
            <h3>Войти на сайт</h3>
            <div className="form-wrapper">
                <form id="form-signin" className="form signin" onSubmit ={(e) => e.preventDefault()}>
                    <label><span>Ваш email</span></label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" id="email" />
                    <label><span>Пароль</span></label>
                    <input onChange= {(e) => setPassword(e.target.value)} value={password} type="password" name="password" id="password"></input>
                    <button type="submit" onClick = {() => sendLogin()}>Войти</button>
                    <button type="submit" onClick = {() => sendReg()}>Регистрация</button>
                </form>
            </div>
        </div>
    );
};

export default SignIn;