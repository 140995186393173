import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {updateUserSettings} from "../../redux/actions/user.js";
import $api from "../../http/api";
import {useMessage} from '../../components/service-components/MessageContext.jsx';

export function ProfileSettings () {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isInitialMount = useRef(true);
    const isInitialUserSettings = useRef(true);
    console.log("ProfileSettings");

    const {addMessage} = useMessage();

    const [formData, setFormData] = useState({});
    const [settingsData, setSettingsData] = useState({});

    const setUserSettings = (e, typeSettings) => {
        isInitialMount.current = false;
        const {name, checked} = e.target;
        setFormData(prevState => ({...prevState, 
            [typeSettings] : {...prevState[typeSettings], [name]: checked}}));
    };

    const saveUserSettings = () => {
        let formdata = new FormData();
        formdata.append('settings', JSON.stringify(formData));
        $api.post(`/user/updateUserSettings`, formdata )
        .then((response) => {
            console.log('Успешно отправлено:', response.data);
            dispatch(updateUserSettings(response.data.settings));
            addMessage({type: "success", text: "Настройки сохранены"});
        })
        .catch((error) => {
            const text = error.response?.data?.message ? error.response.data.message : error.message;
            console.error('Ошибка изменения настроек: ',text);
            addMessage({type: "error", text: text});
        });
    };

    useEffect(() => {
        if (user.load) {
            $api.get('/user/getSettingsData').then(res => {
                setSettingsData(res.data.settings);
            });
        }
    }, [user.load]);

    useEffect(() => {
       if(isInitialUserSettings.current) {
            if (user.userData.settings) {
                setFormData(user.userData.settings);
                isInitialUserSettings.current = false;
            }
       }
    }, [user.userData]);

    useEffect(() => {
        if (!isInitialMount.current) {
            saveUserSettings();
        }
    }, [formData]);

    return (
        <div className="profile-settings profile-block wrapper">
            <div className="head">
                <h1>Настройки тренировок</h1>
            </div>
            <div className="settings--list">
                {settingsData.trainings?.map(settingItem => (
                    <div key={settingItem.name} className="item toggleBtn">
                        <div className="title">{settingItem.title}</div>
                        <input 
                            type="checkbox"  
                            name={settingItem.name} 
                            checked={formData.trainings?.[settingItem.name]} 
                            onChange={(e) => setUserSettings(e, 'trainings')} 
                            id={`switch_${settingItem.name}`} 
                        />
                        {settingItem.type === "switch" && settingItem.values[0].title && <span>{settingItem.values[0].title}</span>}
                        <label htmlFor={`switch_${settingItem.name}`}></label>
                        {settingItem.type === "switch" && settingItem.values[1].title && <span>{settingItem.values[1].title}</span>}
                    </div>
                ))}
            </div>
        </div>
    );
}
