import React, {useState, useEffect} from 'react';
import { Switch, Route, Routes, useLocation, Link, useNavigate } from 'react-router-dom';
import CheckUserRole from "../components/service-components/CheckUserRole.jsx";
import {ProfileLayout} from "../components/profile/ProfileLayout.jsx";
import {ProfileSidebar} from "../components/profile/ProfileSidebar.jsx";

import {Header} from "../components";
import {useMessage} from '../components/service-components/MessageContext.jsx';
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';

const menu = [{name: "Мой профиль", link: "/profile"}, 
{name: "Мой прогресс", link: "/profile/progress"}, 
{name: "Настройки", link: "/profile/settings"},
{name: "Обратная связь", link: "/profile/feedback"}
]

function Profile(){

    const {addMessage} = useMessage();
    const user = useSelector(state => state.user);
    const currentPage = useLocation();
    console.log("currentPage", currentPage);

    return (
        <body className="profile">
        <Header/>
        <content>
                <div className="navigation">
                    <div className="navigation--list">
                        {menu.map(item => (
                            <Link to={item.link}><div className="navigation--item">{item.name}</div></Link>
                        ))}
                    </div>
                </div>
                <div className="content">
                    <ProfileLayout page={currentPage}/>
                </div>
                <div className="right-sidebar">
                    <ProfileSidebar page={currentPage} />
                </div>
        </content>
        </body>
    )
}

export default CheckUserRole(Profile, { isPage: true }, ["user", "teacher", "manager", "admin"]);